import { api, apiUpload } from './api';

export const playlistService = {
  get,
  getSimple,
  getCalendarView,
  getSchedules,
  getZones,
  editPlaylistDetails,
  getOne,
  createPlaylist,
  deletePlaylist,
  addSongs,
  deleteSongs,
  publish,
  uploadArtwork,
  duplicate,
  revert,
  getSuggestedSimilarity,
  getWithSongsUrls,
  getPlaylistTags,
  getSongsIncludedInPlaylist,
  getHistory,
  savePlaylistSortOrder,
  updatePlaylistSortOrder,
  getPlaylistSortOrders,
  deletePlaylistSortOrder
};

function get() {
  return api("GET", "playlist", undefined, playlist => playlist);
}

function getSimple() {
  return api("GET", "playlist/simple", undefined, playlist => playlist);
}

function getCalendarView(clientId) {
  return api("GET", `playlist/calendar/${clientId}`, undefined, playlist => playlist);
}

function getOne(id) {
  return api("GET", `playlist/${id}`, undefined, playlist => playlist);
}

function getSchedules(id) {
  return api("GET", `playlist/${id}/schedules`, undefined, playlist => playlist);
}

function getZones(id) {
  return api("GET", `playlist/${id}/zones`, undefined, playlist => playlist);
}

function editPlaylistDetails(id, name, group, description, tags, internalDescription, allowedExplicitType) {
  return api("PUT", `playlist/${id}`, {
    name,
    playlistGroup: group ? group : null,
    description,
    tags,
    internalDescription,
    allowedExplicitType
  }, playlist => playlist);
}


function duplicate(id, name, description, tags, client) {
  return api("POST", `playlist/${id}/duplicate`, {name, description, tags, client}, playlist => playlist);
}

function createPlaylist(playlist) {
  return api("POST", "playlist", playlist, response => response);
}

function deletePlaylist(id) {
  return api("GET", `playlist/${id}/archive`, undefined, playlist => playlist);
}

function addSongs(playlistId, songIds) {
  return api("POST", `playlist/${playlistId}/song`, songIds, response => response);
}

function deleteSongs(playlistId, songIds) {
  return api("POST", `playlist/${playlistId}/deletesongs`, songIds, response => response);
}

function publish(playlistId) {
  return api("GET", `playlist/${playlistId}/publish`, undefined, playlist => playlist)
}

function revert(playlistId) {
  return api("DELETE", `playlist/revert/${playlistId}`, undefined, playlist => playlist)
}

function uploadArtwork(playlistId, file) {
  return apiUpload(`playlist/${playlistId}/artwork`, "imageFile", file, response => response)
}

function getSuggestedSimilarity(playlistId) {
  return api("GET", `playlist/${playlistId}/similaritySuggestions`, undefined, response => response);
}

function getWithSongsUrls(playlistId) {
  return api("GET", `playlist/${playlistId}/songs`, undefined, playlist => playlist);
}

function getPlaylistTags() {
  return api("GET", "playlist/tags", undefined, playlistTags => playlistTags);
}

function getSongsIncludedInPlaylist(playlistId, songIds) {
  return api("GET", `playlist/${playlistId}/includedSongs?songIds=${songIds}`, undefined, includedCount => includedCount);
}

function getHistory(playlistId) {
  return api("GET", `playlist/${playlistId}/history`, undefined, response => response);
}

function getPlaylistSortOrders(playlistId) {
  return api("GET", `playlist/${playlistId}/playlistSorts`, undefined, response => response);
}

function savePlaylistSortOrder(playlistId, name, sortOrder) {
  return api("POST", `playlist/savePlaylistSort`, {playlistId, name, songIds: sortOrder}, response => response);
}

function updatePlaylistSortOrder(playlistSortOrderId, sortOrder) {
  return api("PUT", `playlist/updatePlaylistSort/${playlistSortOrderId}`, {songIds: sortOrder}, response => response);
}

function deletePlaylistSortOrder(playlistSortOrderId) {
  return api("DELETE", `playlist/deletePlaylistSort/${playlistSortOrderId}`, undefined, response => response);
}
