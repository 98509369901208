import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion';
import { NavLink } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import image from "_assets/images/talea_logo_white.png";
import { DefaultColumnContainer, theme } from "_styles";
import { DragTarget } from './Standard';
import { userActions } from "../_actions";

const Container = styled(DefaultColumnContainer)`
    background-color: ${props => props.theme.leftMenu};
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding-top: 10px;
`;

const BrandContainer = styled(DefaultColumnContainer)`
    align-items: flex-start;
    margin-left: 28px;
    justify-content: center;
    padding: 30px 0px;
`;

const Brand = styled.img`
    width: 100px;
`;

const OuterContainer = styled.div`
    overflow-y: auto;
`;

const Name = styled.div`
    padding-left: 28px;
    margin: 10px 0px;
    text-align: left;
    color: #686868;
    font-size: 14px;
    line-height: 17px;
`;

const Column = styled(DefaultColumnContainer)`
    margin-top: 20px;
`;

const MenuIcon = styled(FontAwesomeIcon)`
    margin-right: 10px;
    color: #64636C;
`;

const GroupIcon = styled(FontAwesomeIcon)`
    color: grey;
`;

const MenuLink = ({to, icon, children, action}) => (
    <NavLink onClick={action} to={to} className="nav-link" activeClassName="nav-active-link">
        <MenuIcon icon={icon} fixedWidth />
      {children}
    </NavLink>
)

const Profile = styled.div`
    display: flex;
    align-items: center;
    right: 25px;
    top: 20px;
`;


const Logout = styled(FontAwesomeIcon)`
    margin-left: 12px;
    color: white;
    cursor: pointer;
`;

export const SideNavbar = ({
                             playlists,
                             pinnedRecentId,
                             handlePin,
                             handleSongDrop,
                             expanded = [],
                             onExpand
                           }) => {

  // Sort playlists
  playlists.sort((a, b) => {
    if (a.name && b.name) {
      return a.name.localeCompare(b.name)
    }
    return 0;
  });

  // Bin playlists by client
  const clients = {}

  playlists.forEach(pl => {
    const client = pl.client || {id: 0, name: 'Master'};
    let bin = clients[client.id];
    if (!bin) {
      bin = clients[client.id] = {
        client,
        playlists: []
      }
    }

    bin.playlists.push(pl);
  })

  let clientAry = Object.values(clients).sort((a, b) => {
    if (a.client.id === 0) return -1;
    if (b.client.id === 0) return 1;
    return a.client.name.localeCompare(b.client.name)
  });
  clientAry.forEach(c => {
        const groupedPlaylists = c.playlists.reduce((groups, item) => ({
          ...groups,
          [item.playlistGroup ? item.playlistGroup : "zzznogroup"]: [...(groups[item.playlistGroup ? item.playlistGroup : "zzznogroup"] || []), item]
        }), {});
        const groups = Object.keys(groupedPlaylists);
        groups.forEach(group => {
          const folders = group.split("/");
          let folder = "";
          for (let i = 0; i < folders.length; i++) {
            const subfolder = folders[i];
            folder += "/" + subfolder;
            if (groups.indexOf(folder.substring(1, folder.length)) === -1) {
              groupedPlaylists[folder.substring(1, folder.length)] = ["empty"];
            }
          }
        })
        c['groupedPlaylists'] = groupedPlaylists;

      }
  );

  const handleLogout = () => {
    this.props.dispatch(userActions.logout(this.props.history));
  }

  return (
      <ThemeProvider theme={theme}>
            <Container>
                <BrandContainer>
                    <Brand src={image} />
                </BrandContainer>
                <OuterContainer>
                    <Column>
                        <Name>ADMIN</Name>
                        <MenuLink to={"/dashboard"} icon={'chart-pie-alt'}>Home</MenuLink>
                        <MenuLink to={'/users'} icon='users'>Users</MenuLink>
                    </Column>
                    <Column>
                        <Name>SYSTEM</Name>
                        <MenuLink to='/clients' icon='building'>Clients</MenuLink>
                        <MenuLink to='/feedback' icon='thumbs-up'>Feedback</MenuLink>
                        <MenuLink to='/samples' icon='cassette-tape'>Samples</MenuLink>
                        <MenuLink to={'/locations'} icon='map-marker-alt'>Locations</MenuLink>
                        <MenuLink to={'/zones'} icon='volume'>Zones</MenuLink>
                        <MenuLink to={'/schedules'} icon='calendar-alt'>Schedules</MenuLink>
                        <MenuLink to={'/devices'} icon='download'>Players</MenuLink>
                    </Column>
                    <Column>
                        <Name>MUSIC</Name>
                        <MenuLink to={'/songs'} icon='music'>Songs</MenuLink>
                        <MenuLink to={'/playlists'} icon='list-ul'>Playlists</MenuLink>
                    </Column>
                    <Column>
                        <Name>PLAYLISTS</Name>
                        <Accordion allowZeroExpanded={true} allowMultipleExpanded={true} preExpanded={expanded}
                            onChange={onExpand}>
                            {clientAry.map(client => {
                              let clientGroupedPlaylists = client.groupedPlaylists;
                              let groupNames = Object.keys(clientGroupedPlaylists).sort();
                              let accordionItems = [];

                              function accordionForGroup(groupName) {
                                let childrenGroups = groupNames.filter(g => g.startsWith(groupName + "/") &&
                                    g !== groupName && (g.substring(groupName.length + 1, g.length).match(/\//g) || []).length === 0);
                                let groupPlaylists = clientGroupedPlaylists[groupName];
                                let accordionItems = groupPlaylists.map((playlist, index) => {
                                  if (playlist === "empty") {
                                    return undefined;
                                  }
                                  const pinned = playlist.id === pinnedRecentId;
                                  return (
                                      <DragTarget
                                          key={playlist.id}
                                          dropData={playlist.id}
                                          dragClassName='drag-hover'
                                          mimeType={'application/songs'}
                                          onDrop={handleSongDrop}
                                          className="hover-block"
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                          }}
                                      >
                                                <NavLink
                                                    to={`/playlist/${playlist.id}`}
                                                    className="nav-link nav-link-sub"
                                                    activeClassName="nav-active-link nav-active-link-sub"
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <span
                                                        style={{color: playlist.active ? "#02FF00" : "transparent"}}>●&nbsp;</span> {playlist.name}
                                                </NavLink>
                                                <div
                                                    className={pinned ? '' : "hover-button"}
                                                    onClick={e => handlePin(playlist.id)}
                                                    style={{
                                                      alignItems: "center",
                                                      cursor: "pointer",
                                                      marginRight: "18px"
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon="thumbtack" size="sm" style={{
                                                      color: pinned ? "orange" : "white"
                                                    }} />
                                                </div>
                                            </DragTarget>
                                  )
                                });
                                if (groupName === "zzznogroup") {
                                  return accordionItems;
                                }
                                for (let i = 0; i < childrenGroups.length; i++) {
                                  let childGroupName = childrenGroups[i];
                                  let accordion = accordionForGroup(childGroupName);
                                  if (accordion) {
                                    accordionItems.push(accordion);
                                    groupNames.splice(groupNames.indexOf(childGroupName), 1)
                                  }
                                }
                                const itemKey = groupName.replace(/\s/g, '') + client.client.id;
                                return <Accordion key={itemKey}
                                    style={{paddingLeft: 10}}
                                    allowZeroExpanded={true}
                                    allowMultipleExpanded={true}
                                    preExpanded={expanded}
                                    onChange={onExpand}>
                                        <AccordionItem
                                            key={itemKey}
                                            uuid={itemKey}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <div><GroupIcon icon={"folder"} />
                                                      &nbsp;
                                                      {groupName.substring(groupName.lastIndexOf("/") + 1, groupName.length)}
                                                    </div>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                {accordionItems}
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                              }

                              for (let i = 0; i < groupNames.length; i++) {
                                let groupName = groupNames[i];
                                accordionItems.push(accordionForGroup(groupName));
                              }
                              return <AccordionItem key={client.client.id} uuid={client.client.id}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {client.client.name}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        {accordionItems}
                                    </AccordionItemPanel>
                                </AccordionItem>;
                            })}
                        </Accordion>
                    </Column>
                    <Column>
                        <Name>SETTINGS</Name>
                        <MenuLink to={'/logout'} icon='sign-out'>Logout</MenuLink>
                    </Column>
                </OuterContainer>
            </Container>
        </ThemeProvider>
  );
};

