import { playerConstants } from "_constants";
import { playerService } from "_services";
import { alertActions } from "./alert.actions";

export const playerActions = {
  get,
  getOne,
  addOne,
  update,
  play,
  playPlaylist,
  setVolume,
  nextPlaylistSongPlaying,
  archive,
  removeUnusedSongs
};

async function nextPlaylistSongPlaying(dispatch, songId) {
  dispatch({type: playerConstants.NEXT_PLAYLIST_SONG_PLAYING, songId});
}


// Fetch protected link to play the song
async function play(dispatch, songId, songTitle, artist, artworkUrl) {
  dispatch({type: playerConstants.PLAY_REQUEST, songTitle});
  try {
    const response = await playerService.getSongUrl(songId);
    dispatch({
      type: playerConstants.PLAY_SUCCESS,
      artist,
      songId,
      songUrl: response.songUrl,
      songTitle,
      songArtworkUrl: artworkUrl
    });
    return response;
  } catch (e) {
    dispatch({type: playerConstants.PLAY_FAILURE});
    throw e;
  }
}

async function playPlaylist(dispatch, playlistId, randomize = false) {
  dispatch({type: playerConstants.PLAY_PLAYLIST_REQUEST, playlistId, randomize});
  try {
    const playlist = await playerService.getPlaylistSongs(playlistId);
    const songs = playlist.songs;
    if (randomize) {
      songs.sort(() => Math.random() - 0.5);
    } else {
      songs.sort((a, b) => {
        const dateDiff = a.dateAddedToPlaylist - b.dateAddedToPlaylist;
        if (dateDiff !== 0) {
          return dateDiff;
        } else {
          return a.id - b.id
        }
      });
    }
    playlist.songs = songs;
    dispatch({type: playerConstants.PLAY_PLAYLIST_SUCCESS, playlistId, playlistPlayback: playlist});
    return playlist;
  } catch (e) {
    dispatch({type: playerConstants.PLAY_PLAYLIST_FAILURE});
    throw e;
  }
}

function get() {
  return dispatch => {
    dispatch({type: playerConstants.GET_REQUEST});

    playerService.get().then(
        player => {
          player = player.map(p => {
            try {
              if (!p.playerInfo) return p;

              const info = JSON.parse(p.playerInfo);

              // Strip playerInfo string
              const {playerInfo: _, ...plr} = p;

              // Parse timezone
              // Server format eg (Wed Oct 23 06:17:13 EST 2019)
              if (info.date) {
                info.timezone = info.date.split(' ')[4];
              }
              return {
                ...plr,
                info
              };
            } catch (e) {
              console.warn(e);
              // if not-present or failure, return plain object
              return p;
            }
          });
          dispatch({type: playerConstants.GET_SUCCESS, player})
        },
        error => {
          dispatch({type: playerConstants.GET_FAILURE, error: error.toString()});
          dispatch(alertActions.error(error.toString()));
        }
    );
  };
}

function getOne(id) {
  return dispatch => {
    dispatch(request());

    playerService.getOne(id).then(
        player => {
          dispatch(success(player));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
    );
  };

  function request() {
    return {type: playerConstants.GET_ONE_REQUEST};
  }

  function success(player) {
    return {type: playerConstants.GET_ONE_SUCCESS, player};
  }

  function failure(error) {
    return {type: playerConstants.GET_ONE_FAILURE, error};
  }
}

function addOne(player) {
  return dispatch => {
    dispatch(request());

    playerService.addOne(player).then(
        player => {
          dispatch(success(player));
          dispatch(alertActions.success())
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
    );
  };

  function request() {
    return {type: playerConstants.ADD_ONE_REQUEST};
  }

  function success(player) {
    return {type: playerConstants.ADD_ONE_SUCCESS, player};
  }

  function failure(error) {
    return {type: playerConstants.ADD_ONE_FAILURE, error};
  }
}

async function update(dispatch, id, name, notes) {
  dispatch({type: playerConstants.UPDATE_ONE_REQUEST});

  try {
    await playerService.update(id, name, notes);
    dispatch({type: playerConstants.UPDATE_ONE_SUCCESS, id, name, notes});
  } catch (e) {
    dispatch({type: playerConstants.UPDATE_ONE_FAILURE, error: e.toString()})
    throw e;
  }
}

function setVolume(playerId, volume) {
  return dispatch => {
    dispatch(request());

    playerService.setVolume(playerId, volume).then(
        player => {
          dispatch(success(player));
          dispatch(alertActions.success())
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
    );
  };

  function request() {
    return {type: playerConstants.VOLUME_REQUEST};
  }

  function success(player) {
    return {type: playerConstants.VOLUME_SUCCESS, player};
  }

  function failure(error) {
    return {type: playerConstants.VOLUME_FAILURE, error};
  }
}

async function archive(dispatch, id) {
  dispatch({type: playerConstants.ARCHIVE_REQUEST})
  try {
    const response = await playerService.archive(id);
    dispatch({type: playerConstants.ARCHIVE_SUCCESS, id: response.id});
    return response;
  } catch (e) {
    dispatch({type: playerConstants.ARCHIVE_FAILURE, error: e.toString()})
    throw e;
  }
}

async function removeUnusedSongs(playerId) {
  await playerService.removeUnusedSongs(playerId);
}

