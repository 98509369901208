import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { alertActions, playerActions, playlistActions, songsActions } from "_actions";
import { debounce } from "lodash";
import {
  DropdownFilter,
  FilterSection,
  IconCheck,
  IconInfo,
  IconPlay,
  IconPlus,
  SearchFilter,
  SectionHeader,
  SectionTitle,
  SortTable,
} from '_components/Standard';
import { AddToMultipleComponent, SectionGutter } from '_components';
import { defineTrackLength, Match } from '_helpers';
import { getActivePlaylistId, getPlaylists } from "_reducers/playlist.reducer";
import { getGenres } from "_reducers/songs.reducer";
import { StandardMoment } from '_styles';
import { getPlaylistLookupView } from "../_reducers/playlist.reducer";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Button, Container, Row } from "react-bootstrap";

import Split from "@uiw/react-split";
import { SongDetailPanelTabbed } from "../_containers/SongDetailPanelTabbed";
import { explicitInfoRowRenderer } from "./ExplicitInfoRowRenderer";

const TotalSongs = styled.div`
    color: rgba(255, 255, 255, .3);
    margin-left: 12px;
`;

export const TitleContainer = styled(Container)`
    max-width: 100%;
    margin: 10px;
`;

const DatePickerButton = styled.button`
    border: 0;
    border-bottom: 1px solid ${props => props.theme.optionalGray};
    color: #919192;
    padding: 4px 4px 4px 0;
    letter-spacing: 1px;
    height: 42px;
    background-color: #222128;
    outline: none;
    box-shadow: none;
    width: 100%;
    text-align: left;

    ::placeholder {
        color: white;
    }

    &:focus,
    &:active,
    &:hover {
        background-color: #222128;
        border: 0;
        border-bottom: 1px solid ${props => props.theme.optionalGray};
        outline: none;
        box-shadow: none;
        caret-color: white;
        color: white;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        border: 0;
        -webkit-text-fill-color: white;
        transition: background-color 5000s ease-in-out 0s;
    }
`;

const FillRight = styled.div`
    flex: 1;
    font-size: 10px;
    color: rgba(255, 255, 255, .3);
    text-align: right;
    text-transform: uppercase;
`;

const explicitTypes = ["CLEAN", "EXPLICIT", "ADULT"];

export const songTitleRowRenderer = (songData, currentPlayingSongId, currentSongInfoId, handleTrackTitleClick) => {
  let color = "";
  if (songData.id === currentPlayingSongId) {
    color = "#F7A83C";
  }
  const StyledSpanTitle = styled.span`
      &:hover {
          text-decoration: underline;
      }
  `;
  return <StyledSpanTitle onClick={e => {
    e.stopPropagation();
    handleTrackTitleClick(songData);
  }}
      style={{
        textOverflow: "ellipsis",
        //overflow: "hidden",
        color: color,
        textDecoration: currentSongInfoId === songData.id ? "underline" : ""
      }}>{songData.title}</StyledSpanTitle>

};

export const explicitSortFunction = (a, b, isAsc = false) => {
  let songA, songB
  if (isAsc) {
    songA = a;
    songB = b;
  } else {
    songA = b;
    songB = a;
  }
  const explicitOrder = ["EXPLICIT", "ADULT", "CLEAN"];
  const verifiedOrder = [true, false];
  const aExplicitIndex = explicitOrder.indexOf(songA.songExplicitType);
  const bExplicitIndex = explicitOrder.indexOf(songB.songExplicitType);
  const aVerifiedIndex = verifiedOrder.indexOf(songA.verified ? songA.verified : false);
  const bVerifiedIndex = verifiedOrder.indexOf(songB.verified ? songB.verified : false);
  if (!songB.songExplicitType && !songA.songExplicitType) {
    return 0;
  }
  if (!songA.songExplicitType) {
    return 1;
  }
  if (!songB.songExplicitType) {
    return -1;
  }
  if (aExplicitIndex < bExplicitIndex) {
    return -1;
  }
  if (aExplicitIndex > bExplicitIndex) {
    return 1;
  }
  if (aVerifiedIndex < bVerifiedIndex) {
    return -1;
  }
  if (aVerifiedIndex > bVerifiedIndex) {
    return 1;
  }
  return 0;
}


class Songs extends React.Component {
  state = {
    songs: [],
    filterText: sessionStorage.getItem('songs_filter_text') || undefined,
    filterExplicit: sessionStorage.getItem('songs_filter_explicit'),
    selectedSongs: [],
    songPanelHeight: sessionStorage.getItem('songs_song_panel_height') || 50,
    updatedSongs: [],
    selectedArtist: undefined,
    artists: [],
    filterArtist: false
  };


  constructor(props) {
    super(props);
    this.modalContainerRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.filterBpm = undefined;
    this.filterGenre = undefined;
    this.filterExplicit = undefined;
    this.filterYear = undefined;
    this.filterDate = undefined;
    const query = new URLSearchParams(this.props.location.search);
    const filterArtist = query.get("filterArtist");
    if (filterArtist) {
      this.setState({filterText: filterArtist, filterArtist: true});
      this.state.filterText = filterArtist;
      this.state.filterArtist = filterArtist;
    }
    let artists = new Set();
    this.props.songsGlobal.forEach(s => s.artist && s.artist.name && artists.add(JSON.stringify(s.artist)));
    artists = [...artists].map(a => JSON.parse(a));
    artists.sort((a, b) => a.name.localeCompare(b.name));
    this.setState({artists: artists});
    this.filter();
    const storedSongInfoId = sessionStorage.getItem('songs_song_info_id');
    if (storedSongInfoId) {
      setTimeout(() => this.setState({songInfoId: storedSongInfoId}), 100);
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.songsGlobal !== this.props.songsGlobal) {
      this.filter();
    }
    if (prevState.songInfoId !== this.state.songInfoId) {
      console.log("forced update");
      setTimeout(() => {
        this.forceUpdate()
      }, 1000);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }


  onSongUpdated = (song) => {
    this.setState({
      songs: this.state.songs.map(s => s.id === song.id ? {
        ...s,
        notes: song.notes,
        verified: song.verified,
        verifier: song.verifier,
        songExplicitType: song.songExplicitType
      } : s)
    });
  }

  handleSongsPanelHeightChanged = (height) => {
    const windowHeight = window.innerHeight;
    const percentage = height / (windowHeight - 84) * 100;
    sessionStorage.setItem('songs_song_panel_height', percentage);
  }

  moreFromSameArtistClicked = () => {
    const artist = this.props.songsGlobal.find(s => s.id === this.state.songInfoId).artist;
    if (artist !== undefined) {
      this.setState({filterText: artist.name, filterArtist: true}, () => this.filterTracks(artist.name));
    }
  }

  handleClickOutside(event) {
    if (this.modalContainerRef && this.modalContainerRef.current && !this.modalContainerRef.current.contains(event.target)) {
      this.setState({songInfoId: undefined});
    }
  }

  filterTracks = filterText => {
    this.state.filterText = filterText.length > 0 ? filterText.toLowerCase() : undefined;
    if (!this.state.filterText) {
      this.state.filterArtist = false;
    }
    this.setState({
      filterText: filterText.toLowerCase(),
    }, () => sessionStorage.setItem('songs_filter_text', filterText.toLowerCase()));
    debounce(() => this.filter(), 500)();
  };

  handleInfo = song => {
    this.setState({songInfoId: song.id, songPanelHeight: 95}, () => {
      sessionStorage.setItem('songs_song_info_id', song.id);
      sessionStorage.removeItem('songs_song_panel_height');
    });
  }

  handleTrackTitleCLick = song => {
    this.setState({songInfoId: song.id, songPanelHeight: 50}, () => {
      sessionStorage.setItem('songs_song_info_id', song.id);
      sessionStorage.removeItem('songs_song_panel_height');
    });
  }

  handleAddSong = song => {
    playlistActions.addSongs(this.props.dispatch, this.props.activePlaylistId, [song.id])
  };

  handleDeleteSong = song => {
    playlistActions.deleteSongs(this.props.dispatch, this.props.activePlaylistId, [song.id])
  };

  handleFilterGenre = selected => {
    this.filterGenre = selected;
    this.filter();
  }

  handleFilterArtist = selected => {
    let selectedArtist;
    if (selected) {
      selectedArtist = this.props.songsGlobal.map(s => s.artist).find(a => a.name === selected.text);
    }
    this.setState({selectedArtist: selectedArtist});
    this.state.selectedArtist = selectedArtist;
    this.filter();
  }

  handleFilterExplicit = selected => {
    const explicitType = selected ? selected.id : undefined;
    this.state.filterExplicit = explicitType
    this.setState({
      filterExplicit: explicitType
    }, () => {
      if (explicitType) {
        sessionStorage.setItem('songs_filter_explicit', explicitType);
      } else {
        sessionStorage.removeItem('songs_filter_explicit');
      }
    });
    this.filter();
  }

  handleFilterDateAdded = dateSelected => {
    this.filterDate = dateSelected;
    this.filter();
  };

  filter = () => {
    const {filterText, filterArtist, selectedArtist, filterExplicit} = this.state;
    if (filterArtist) {
      this.setState({
        songs: this.props.songsGlobal.filter(song =>
            Match(song, filterArtist.text, ["artist.name"])
        )
      });
    } else {
      const matchingSongs = this.props.songsGlobal.filter(song => {
        if (this.filterYear && (!song.yearReleased || song.yearReleased < this.filterYear.start || song.yearReleased > this.filterYear.end)) return false;
        if (this.filterBpm && (!song.bpm || song.bpm < this.filterBpm.start || song.bpm > this.filterBpm.end)) return false;
        if (this.filterGenre && (!song.genres || !song.genres.find(g => g.id === this.filterGenre.id))) return false;
        if (selectedArtist && (!song.artist || song.artist.id !== selectedArtist.id)) return false;
        if (filterExplicit && (!song.songExplicitType || song.songExplicitType !== filterExplicit)) return false;
        if (filterText && !Match(song, filterText, ["title", "artist.name", "album.name", "filename"])) return false;
        if (this.filterDate) {
          const date = this.filterDate;
          const songDate = new Date(song.dateAdded);
          if (songDate.getFullYear() !== date.getFullYear() || songDate.getMonth() !== date.getMonth() || songDate.getDate() !== date.getDate()) return false;
        }
        return true;
      });

      this.setState({
        songs: matchingSongs
      });
    }
  }

  songSelectionHandler = songs => {
    this.setState({selectedSongs: songs});
  }

  saveSongsToMultiple = async (playlists, hide) => {
    const {dispatch} = this.props;
    const songIds = this.state.selectedSongs.map(s => s.id);

    // TODO rewire hide()
    hide();

    try {
      const results = playlists.map(pl => playlistActions.addSongs(dispatch, pl.id, songIds));
      await Promise.all(results)
      alertActions.notificationSuccess(dispatch, "Songs Added", `${songIds.length} songs added to ${playlists.length} playlists`);
    } catch (e) {
      alertActions.notificationError(dispatch, "Error", e.toString());
    }
  }

  playSong = (song) => {
    playerActions.play(this.props.dispatch, song.id, song.title, song.artist.name, song.artworkUrl);
  }
  nextSongClicked = () => {
    let currentSongInfoIdIndex = this.sortedSongs.findIndex(s => s.id === this.state.songInfoId);
    if (currentSongInfoIdIndex < this.sortedSongs.length - 1) {
      this.setState({songInfoId: this.sortedSongs[currentSongInfoIdIndex + 1].id});
    }
  }
  prevSongClicked = () => {
    let currentSongInfoIdIndex = this.sortedSongs.findIndex(s => s.id === this.state.songInfoId);
    if (currentSongInfoIdIndex > 0) {
      this.setState({songInfoId: this.sortedSongs[currentSongInfoIdIndex - 1].id});
    }
  }
  onSorted = (sorted) => {
    this.sortedSongs = sorted;
  };

  render() {
    const playColumnWidth = 30;
    const songsPanelHeight = this.state.songInfoId ? (100 - this.state.songPanelHeight) + "%" : "100%";

    return <Split lineBar mode={"vertical"}>
      <div style={{
        height: (this.state.songInfoId ? songsPanelHeight : "100%"),
        overflowY: "scroll",
        overflowX: "hidden"
      }}>
        <TitleContainer>
          <Row>
            <SectionHeader>
              <SectionTitle>Songs</SectionTitle>
            </SectionHeader>
          </Row>
          <Row>
            <FilterSection style={{marginLeft: SectionGutter, marginRight: SectionGutter}}>
              <SearchFilter textColor={this.state.filterArtist ? "#47fa00" : undefined}
                  onChange={this.filterTracks}
                  value={this.state.filterText} />
              <DropdownFilter onSelect={this.handleFilterArtist} placeholder="Artist"
                  options={this.state.artists.map(artist => (
                      {id: artist.id, text: artist.name}
                  ))}
                  maxItems={50}
              />
              <DropdownFilter onSelect={this.handleFilterGenre} placeholder="Genre"
                  options={this.props.genres.map(g => (
                      {id: g.id, text: g.name}
                  ))} />
              <DropdownFilter onSelect={this.handleFilterExplicit} placeholder="Explicit"
                  selectedId={this.state.filterExplicit}
                  options={explicitTypes.map(e => (
                      {id: e, text: e}
                  ))} />
              <div style={{width: 200, marginRight: 0}}>
                <DatePicker
                    showIcon
                    value={this.filterDate}
                    onChange={this.handleFilterDateAdded}
                    customInput={<DatePickerButton>
                      {this.filterDate ? this.filterDate.getFullYear() + "-" + (this.filterDate.getMonth() + 1) + "-" + this.filterDate.getDate() : "Filter date"}
                    </DatePickerButton>
                    }>
                </DatePicker>
              </div>
              {this.filterDate && <Button style={{marginLeft: 10, background: "gray", border: 0}}
                  onClick={() => this.handleFilterDateAdded(undefined)}>X</Button>}
            </FilterSection>
          </Row>

          <Row style={{marginLeft: SectionGutter, marginRight: SectionGutter}}>
            <AddToMultipleComponent songCount={this.state.selectedSongs.length}
                onSave={this.saveSongsToMultiple}
                playlists={this.props.playlists} />
            <TotalSongs>Total songs {this.state.songs.length}</TotalSongs>
            <FillRight>
              Refresh the browser if new songs have been added
            </FillRight>
          </Row>
        </TitleContainer>
        <SortTable
            tableName={"songs"}
            gutter={SectionGutter - playColumnWidth}
            onRowDoubleClick={this.playSong}
            rows={this.state.songs}
            onSelection={this.songSelectionHandler}
            onSorted={this.onSorted}
            columns={[
              {
                field: 'play',
                noTooltip: true,
                fixed: playColumnWidth, rowRenderer: row => (
                    <div className="onRowHover">
                      <IconPlay onClick={e => {
                        e.stopPropagation();
                        this.playSong(row);
                      }} />
                    </div>
                )
              },
              {
                field: 'check',
                noTooltip: true,
                fixed: 20,
                rowRenderer: row => (
                    this.props.activeSongs[row.id] ? (
                        <IconCheck color='orange' onClick={e => {
                          e.stopPropagation();
                          this.handleDeleteSong(row)
                          row.needsRefresh = true;
                        }} />
                    ) : (
                        <IconPlus onClick={e => {
                          e.stopPropagation();
                          this.handleAddSong(row)
                          row.needsRefresh = true;
                        }} />
                    )
                )
              },
              {
                field: 'info_icon',
                noTooltip: true,
                fixed: 20, rowRenderer: row => {
                  return <>
                    <IconInfo opacity={0.4} onClick={e => {
                      e.stopPropagation();
                      this.handleInfo(row)
                    }} />

                  </>
                }
              },
              // 25
              {
                percent: 20,
                title: 'Track',
                field: 'title',
                rowRenderer: (row) => songTitleRowRenderer(row, this.props.currentPlayingSongId, this.state.songInfoId, this.handleTrackTitleCLick)
              },
              {percent: 20, title: 'Artist', field: 'artist.name'},
              {percent: 20, title: 'Album', field: 'album.name', collapse: 800},
              {
                fixed: 170,
                title: 'Uploaded (Local)',
                isNumber: true,
                field: 'dateAdded',
                collapse: 500,
                dim: true,
                rowRenderer: row => (
                    <StandardMoment>{row.dateAdded}</StandardMoment>
                )
              },
              {fixed: 70, title: 'BPM', isNumber: true, field: 'bpm', collapse: 900, dim: true},
              {
                fixed: 70,
                title: 'Year',
                isNumber: true,
                field: 'yearReleased',
                collapse: 1000,
                dim: true
              },
              {
                fixed: 70,
                title: 'length',
                isNumber: true,
                field: 'trackLength',
                collapse: 1100,
                dim: true,
                rowRenderer: row => (
                    defineTrackLength(row.trackLength)
                )
              },
              {
                fixed: 70,
                title: 'Explicit',
                field: 'songExplicitType',
                collapse: 1100,
                sortFunction: explicitSortFunction,
                rowRenderer: row => {
                  return explicitInfoRowRenderer(row)
                }
              },
              {
                fixed: 70,
                title: 'Playlists',
                isNumber: true,
                field: 'playlistCount',
                collapse: 1000,
                dim: true
              },
            ]
            } />
      </div>
      {this.state.songInfoId && <SongDetailPanelTabbed id={this.state.songInfoId}
          nextSongClicked={this.nextSongClicked}
          prevSongClicked={this.prevSongClicked}
          onSongUpdated={this.onSongUpdated}
          moreFromSameArtistClicked={this.moreFromSameArtistClicked}
          heightChanged={this.handleSongsPanelHeightChanged}
          height={this.state.songPanelHeight + "%"}
          closeClicked={() => this.setState({songInfoId: null}, () => {
            sessionStorage.removeItem('songs_song_info_id');
            sessionStorage.removeItem('songs_song_panel_height');
          })}></SongDetailPanelTabbed>}
    </Split>;

  }
}

const mapStateToProps = (state) => {
  const activePlaylistId = getActivePlaylistId(state);
  return {
    activePlaylistId,
    playlists: getPlaylists(state),
    activeSongs: getPlaylistLookupView(state, activePlaylistId),
    songsGlobal: state.songs.songs || [],
    artistsGlobal: state.songs.artists || [],
    loading: state.songs.loading,
    alert: state.alert,
    genres: getGenres(state),
    currentPlayingSongId: state.player.songId
  }
};

const connectedSongs = withRouter(connect(mapStateToProps)(Songs));
export { connectedSongs as Songs };

