import React from "react";
import { TaleaAudioPlayerSamples } from "./AudioPlayerSamples";
import { sampleActions } from "../../_actions/sample.actions";
import { sortSongsBasedOnIndex } from "../Utils";

export const TaleaPlaybackControllerSamples = ({
                                                 loading,
                                                 playlistPlayback = null,
                                                 selectedSong = null,
                                                 dispatch,
                                                 playing,
                                                 playlistIndex,
                                                 currentSong,
                                                 timeSent
                                               }) => {

  const nextSong = () => {
    sampleActions.playNextSongInPlaylist(dispatch);
  }
  const prevSong = () => {
    sampleActions.playPrevSongInPlaylist(dispatch);
  }

  if (playlistPlayback) {
    let songs = playlistPlayback.songs;
    if (playlistPlayback.playlistSortId) {
      songs = sortSongsBasedOnIndex(songs, playlistPlayback.playlistSortDtos.find(ps => ps.id === playlistPlayback.playlistSortId).songIds);
    }
    let song = currentSong;
    if (!song && !selectedSong) {
      song = songs[playlistIndex];
    } else if (selectedSong) {
      song = selectedSong;
    }
    const showSongDetails = () => {
      sampleActions.showSongDetails(dispatch);
    }

    return <TaleaAudioPlayerSamples loading={loading}
        source={song.songUrl}
        title={song.title}
        artist={song.artist.name}
        artworkUrl={song.artworkUrl}
        playlistName={playlistPlayback.name}
        playlistIndex={playlistIndex}
        playlistId={playlistPlayback.id}
        playlistTotal={songs.length}
        showSongDetails={showSongDetails}
        nextSong={nextSong} prevSong={prevSong}
        dispatch={dispatch}
        playing={playing}
        timeSent={timeSent}
    />
  } else {
    return <TaleaAudioPlayerSamples />
  }
}
