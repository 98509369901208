import React from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { ActivePlaylist, PlaylistDetail, Playlists, Songs } from "_components";
import { SongSearch } from '_containers';
import { DefaultRowContainer } from '_styles';
import { Feedback } from "./Feedback";
import { SongDetailPanelTabbed } from "./SongDetailPanelTabbed";

const Container = styled(DefaultRowContainer)`
    display: flex;
    height: 100%
`;

const GrowBox = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
`;

const FloatBox = styled.div`
    display: flex;
    flex: 0 1 auto;
`;

export const Music = () => {
  return (
      <Container>
      <GrowBox>
        <Switch>
          <Route path="/search" component={SongSearch} />
          <Route path="/songs" component={Songs} />
          <Route path="/playlists" component={Playlists} />
          <Route path="/playlist/:id" component={PlaylistDetail} />
          <Route path="/feedback" component={Feedback} />
          <Route exact path="/song/:id"
              component={() => <div
                  style={{paddingTop: 80, height: "100%", background: "#1B1A20"}}><SongDetailPanelTabbed
                  closeClicked={
                    () => {
                      window.history.back();
                    }
                  } /></div>} />
        </Switch>
      </GrowBox>
      <FloatBox>
        <ActivePlaylist />
      </FloatBox>
    </Container>
  )
};