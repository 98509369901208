import React, { useEffect, useState } from "react";
import { DropdownFilter, Icon, IconHover, IconInfo, IconPlay, SortTable } from "./Standard";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ModalDecisionBuilder } from "./Utils";
import { TextField } from "@material-ui/core";
import { playlistActions } from "../_actions";
import { defineTrackLength } from "../_helpers";
import { playlistService } from "../_services";
import styled from "styled-components";

const IconArrowUp = props => <Icon icon={['fas', 'arrow-up']} {...props} />;
const IconArrowDown = props => <Icon icon={['fas', 'arrow-down']} {...props} />;

const StyledTextField = styled(TextField)`
    /* default */

    .MuiInput-underline:before {
        border-bottom: 2px solid white;
    }

    /* hover (double-ampersand needed for specificity reasons. */

    && .MuiInput-underline:hover:before {
        border-bottom: 2px solid white;
    }

    /* focused */

    .MuiInput-underline:after {
        border-bottom: 2px solid white;
    }
`;


export const SongsSortComponent = ({playlistId, playlistSongs, dispatch}) => {
  const [songs, setSongs] = useState(playlistSongs);
  const [playlistSortOrders, setPlaylistSortOrders] = useState([]);
  const [selectedList, setSelectedList] = useState();
  const [showSave, setShowSave] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  let songs2 = [...songs];

  const loadPlaylistSortOrders = () => {
    playlistService.getPlaylistSortOrders(playlistId).then(playlistSorts => {
      setSelectedList(undefined);
      setPlaylistSortOrders(playlistSorts);
    });
  }

  useEffect(() => {
    loadPlaylistSortOrders();
  }, []);


  const moveSongUp = (index, amount) => {
    // move song x amount of times up in the list. Move all the songs in between 1 step down.
    if (index === 0) return;
    let song = songs2[index];
    let i = index;
    let targetIndex = Math.max(index - amount, 0);
    while (i > targetIndex && i > 0) {
      songs2[i] = songs2[i - 1];
      i--;
    }
    songs2[i] = song;
    setSongs(songs2);
  }

  const moveSongDown = (index, amount) => {
    // move song x amount of times down in the list. Move all the songs below the new song position 1 step down.
    if (index === songs2.length - 1) return;
    let song = songs2[index];
    let i = index;
    let targetIndex = Math.min(index + amount, songs2.length - 1);
    while (i < targetIndex && i < songs2.length - 1) {
      songs2[i] = songs2[i + 1];
      i++;
    }
    songs2[i] = song;
    setSongs(songs2);
  }

  const onSelect = (list) => {
    if (!list) {
      setSelectedList(undefined);
      return;
    }
    setSelectedList(list);
    const listId = list.id;
    const playlistSortOrder = playlistSortOrders.find(list => list.id === listId);
    const songsIds = playlistSortOrder.songIds;
    // sort songs based on the list
    let sortedSongs = songsIds.map(id => songs.find(song => song.id === id));
    setSongs(sortedSongs);
  }

  const handleUpdateClick = () => {
    setShowUpdate(true);
  }

  const handleSaveClick = () => {
    setShowSave(true);
  }

  const handleDeleteClick = () => {
    setShowDelete(true);
  }

  const options = playlistSortOrders.map(list => ({id: list.id, text: list.name}));

  const DeleteSortOrderModal = () => {
    return <ModalDecisionBuilder
        title={"Delete Sort Order"}
        message={"Do you want to delete the sort order?"}
        handleAccept={async () => {
          await playlistService.deletePlaylistSortOrder(selectedList.id);
          setTimeout(loadPlaylistSortOrders, 500);
          setShowDelete(false);
        }}
        onCancel={() => {
          setShowDelete(false);
        }}
        handleClose={() => {
          setShowDelete(false);
        }}
    />
  }


  const SaveSortOrderModal = () => {
    const [name, setName] = useState("");
    return <ModalDecisionBuilder
        title={(showSave ? "Save" : "Update") + " Sort Order"}
        message={"Do you want to " + (showSave ? "save" : "update") + " the sort order?"}
        content={showUpdate ? "Do you want to update the sort order?" :
            <StyledTextField
                placeholder={"Enter name"} inputProps={{style: {color: "white"}}} value={name} onChange={(e) => {
              setName(e.target.value);
            }
            }
            ></StyledTextField>}
        handleAccept={() => {
          if (showSave) {
            if (name.trim().length === 0) {
              return;
            }
            if (playlistSortOrders.find(list => list.name === name)) {
              return;
            }
            dispatch(playlistActions.savePlaylistSortOrder(playlistId, name, songs2.map(song => song.id)));
          } else if (showUpdate) {
            dispatch(playlistActions.updatePlaylistSortOrder(selectedList.id, songs2.map(song => song.id)));
          }
          setTimeout(loadPlaylistSortOrders, 500);
          setShowSave(false);
          setShowUpdate(false);
        }}

        onCancel={() => {
          setShowSave(false);
          setShowUpdate(false);
        }}
        handleClose={() => {
          setShowSave(false);
          setShowUpdate(false);
        }}
    />
  }

  const ClickableInput = ({value, onChange}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const switchToEdit = () => {
      setIsEditing(true);
    }
    const switchToView = () => {
      setIsEditing(false);
    }
    return isEditing ?
        <TextField inputProps={{style: {color: "white"}}} style={{color: "white"}} value={inputValue} onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (e.target.value.match(/^\d+$/)) {
              onChange(e.target.value);
            }
          } else {
            setInputValue(e.target.value);
          }
        }} onChange={
          (e) => {
            if (e.target.value.match(/^\d+$/) || e.target.value === "") {
              if (e.target.value === "" || (e.target.value > 0 && e.target.value <= songs2.length)) {
                setInputValue(e.target.value);
              }
            }
          }}

            onBlur={switchToView} autoFocus></TextField> : <div style={{color: "white"}} onClick={switchToEdit}>{value}</div>;
  }


  return <Container style={{height: "100%", maxHeight: "100%", margin: 10, marginTop: 10, padding: 0}}>
    <Row>
      <Col xs={"auto"}>
        <DropdownFilter selectedTextColor='orange' onSelect={onSelect}
            selectedId={selectedList}
            placeholder="Sorted lists"
            options={options} />
        </Col>
      <Col xs={"auto"}>
      <Col style={{width: 50, paddingTop: 10}} className="align-items-center d-flex justify-content-center">
                     {selectedList && <IconHover style={{marginRight: 0}} color='white' opacity=".3" icon="trash-alt"
                         onClick={handleDeleteClick} data-tip="Delete" />}
      </Col>
      </Col>
      <Col xs={"auto"}>
        <Button style={{width: 100, background: "#EBAD18"}} onClick={handleUpdateClick} disabled={!selectedList}>Update</Button>
      </Col>
      <Col xs={"auto"}>
        <Button style={{width: 100, background: "#EBAD18"}} onClick={handleSaveClick}>Save</Button>
      </Col>
    </Row>
    <Row>
      <Col>
                                  <SortTable
                                      tableName={"sort_songs_table"}
                                      noCache={true}
                                      gutter={18}
                                      rows={songs2}
                                      columns={[
                                        {
                                          fixed: 30,
                                          noTooltip: true,
                                          rowRenderer: row => {
                                            let singleClickTimer;
                                            const handleClick = (doubleClick, arrow) => {
                                              let color = "green"
                                              row.goingUp = true;
                                              arrow.style.color = color;
                                              setTimeout(() => {
                                                arrow.style.color = "white";
                                              }, 500);
                                              if (!doubleClick) {
                                                singleClickTimer = setTimeout(() => {
                                                  moveSongUp(songs2.indexOf(row), 1);
                                                }, 250);
                                              } else {
                                                clearTimeout(singleClickTimer);
                                                moveSongUp(songs2.indexOf(row), 10);
                                              }
                                            }
                                            return <div>
                                                    {songs2.indexOf(row) !== 0 && <IconArrowUp
                                                        onDoubleClick={e => {
                                                          handleClick(true, e.target)
                                                        }}
                                                        onClick={e => {
                                                          handleClick(false, e.target);
                                                        }}
                                                    />}</div>
                                          }
                                        },
                                        {
                                          fixed: 30,
                                          noTooltip: true,
                                          rowRenderer: row => (<ClickableInput value={songs2.indexOf(row) + 1}
                                              onChange={(value) => {
                                                let index = songs2.indexOf(row);
                                                if (value - 1 < index) {
                                                  moveSongUp(index, index - (value - 1));
                                                } else if (value > index) {
                                                  moveSongDown(index, (value - 1) - index);
                                                }
                                              }}></ClickableInput>)
                                        },

                                        {
                                          fixed: 30,
                                          noTooltip:
                                              true,
                                          rowRenderer: row => {
                                            let singleClickTimer;
                                            const handleClick = (doubleClick, arrow) => {
                                              let color = "red"
                                              row.goingUp = true;
                                              arrow.style.color = color;
                                              setTimeout(() => {
                                                arrow.style.color = "white";
                                              }, 500);
                                              if (!doubleClick) {
                                                singleClickTimer = setTimeout(() => {
                                                  moveSongDown(songs2.indexOf(row), 1);
                                                }, 250);
                                              } else {
                                                clearTimeout(singleClickTimer);
                                                moveSongDown(songs2.indexOf(row), 10);
                                              }
                                            }
                                            return <div>
        {songs2.indexOf(row) !== songs2.length - 1 && <IconArrowDown
            onDoubleClick={e => {
              handleClick(true, e.target)
            }}
            onClick={e => {
              handleClick(false, e.target);
            }}
        />}</div>
                                          }
                                        },
                                        {
                                          fixed: 30,
                                          noTooltip:
                                              true, rowRenderer:
                                              row => (
                                                  <div className="onRowHover">
                                                    <IconPlay onClick={e => {
                                                      e.stopPropagation();
                                                      //       this.playSong(row);
                                                    }} />
                                                  </div>
                                              )
                                        },
                                        {
                                          fixed: 20,
                                          noTooltip: true,
                                          rowRenderer: row => (
                                              <IconInfo color={'grey'} opacity={.4} onClick={e => {
                                                e.stopPropagation();
                                                moveSongUp(songs.indexOf(row));
                                                //       this.handleInfo(row)
                                              }} />)
                                        }
                                        ,
                                        {
                                          title: 'Track',
                                          field:
                                              'title',
                                          percent:
                                              25,
                                        }
                                        ,
                                        {
                                          title: 'Artist',
                                          field:
                                              'artist.name',
                                          percent:
                                              20
                                        }
                                        ,
                                        {
                                          title: 'Album',
                                          field:
                                              'album.name',
                                          percent:
                                              20
                                        }
                                        ,
                                        {
                                          title: 'BPM',
                                          field:
                                              'bpm',
                                          isNumber:
                                              true,
                                          fixed:
                                              70,
                                          collapse:
                                              800,
                                          dim:
                                              true
                                        }
                                        ,
                                        {
                                          title: 'Year',
                                          field:
                                              'yearReleased',
                                          isNumber:
                                              true,
                                          fixed:
                                              70,
                                          collapse:
                                              900,
                                          dim:
                                              true
                                        }
                                        ,
                                        {
                                          title: 'length',
                                          field: 'trackLength',
                                          isNumber: true,
                                          fixed: 70,
                                          collapse: 1000,
                                          dim: true,
                                          rowRenderer:
                                              row => (defineTrackLength(row.trackLength))
                                        }
                                      ]}
                                  />
</Col>
</Row>
    {(showSave || showUpdate) && <SaveSortOrderModal></SaveSortOrderModal>}
    {(showDelete) && <DeleteSortOrderModal></DeleteSortOrderModal>}
</Container>

}