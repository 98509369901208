import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import styled, { ThemeProvider } from "styled-components";
import { alertActions, campaignActions, clientActions, playlistActions } from "_actions";
import {
  CreationForms,
  DefaultBlackFormControl,
  DefaultColumnContainer,
  DefaultLabel,
  DefaultRowContainer,
  Helper,
  theme
} from "_styles";
import { ModalDecisionBuilder } from './Utils';
import DatePicker from "react-datepicker";
import moment from "moment";
import { campaignService } from "../_services";
import { ImagePickModal } from "./ImagePickModal";

function hashCode(s) {
  var h = 0, l = s.length, i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
}

const DetailsContainer = styled(DefaultColumnContainer)`
    margin-top: 30px;
    width: 100%;
    align-items: flex-start;
`;

const Image = styled.div`
    background-color: hsl(${props => props.hue}, 90.6%, 66.7%);
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    display: flex;
`;
const RowContainer = styled(DefaultRowContainer)`
    width: 100%;
    align-items: center;

    & + & {
        margin-top: 20px;
    }
`;

const DatePickerWrapper = styled.div`
    width: 100%;
`;


const Label = styled(DefaultLabel)`
    color: white;
    font-size: 24px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
`;

const FormContainerWithHelper = styled(DefaultColumnContainer)`
    width: 100%;
    align-items: flex-start;
    margin-top: 10px;
`;

const FormLabel = styled(DefaultLabel)`
    color: ${props => props.theme.formlabel};
    font-size: 14px;
`;

const Form = styled(DefaultBlackFormControl)`
    margin-top: 5px;
`;

const RSelect = styled(Select)`
    width: 100%;
    margin-top: 10px;
`;

const DatePickerButton = styled.button`
    border: 1px solid ${props => props.theme.optionalGray};
    color: black;
    padding: 4px 4px 4px 10px;
    letter-spacing: 1px;
    height: 50px;
    background-color: ${props => props.theme.white};
    outline: none;
    box-shadow: none;
    width: 100%;
    text-align: left;
    border-radius: 5px;

    ::placeholder {
        color: white;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        border: 0;
        -webkit-text-fill-color: white;
        transition: background-color 5000s ease-in-out 0s;
    }
`;

const HalfDatePickerButton = styled(DatePickerButton)`
    width: 50%;
`;

class DatePickerInput extends React.Component {
  render() {
    return this.props.half ? (
        <HalfDatePickerButton onClick={this.props.onClick}>
                {this.props.value}
            </HalfDatePickerButton>
    ) : (
        <DatePickerButton onClick={this.props.onClick}>
                {this.props.value}
            </DatePickerButton>
    );
  }
}

const playlistSortName = (playlists, playlistSort) => {
  const playlist = playlists.find(p => p.id === playlistSort.playlistId);
  return playlist ? playlist.name + " (" + playlistSort.name + " sort)" : "";
}

function playlistSortOptionValue(p) {
  return p.playlistId + "#" + p.id;
}

const CreateCampaign = ({
                          campaign = undefined,
                          onBack,
                          onCreate,
                          onArchive,
                          dispatch,
                          clients,
                          playlists,
                          imagesUrls = []
                        }) => {
  const isEditing = campaign !== undefined;

  const [client, setClient] = useState(isEditing ? {
    value: campaign.client.id,
    label: campaign.client.name
  } : undefined);


  const [name, setName] = useState(isEditing ? campaign.name : '');
  const [description, setDescription] = useState(isEditing ? campaign.description : '');
  const [campaignArtworkUrl, setCampaignArtworkUrl] = useState(isEditing ? campaign.artworkUrl : undefined);

  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [confirmArchiving, setConfirmArchiving] = useState(false);
  const [selectedMasterPlaylists, setSelectedMasterPlaylists] = useState([]);
  const [selectedClientPlaylists, setSelectedClientPlaylists] = useState([]);
  const [expirationDate, setExpirationDate] = useState(moment().add(30, 'days').toDate());
  const [imagePicked, setImagePicked] = useState(false);
  const imageUrlInput = useRef();
  const [showPickImageModal, setShowPickImageModal] = useState(false);

  let clientPlaylists = [];
  let masterPlaylists = [];

  useEffect(() => {
    clientActions.getAllClients(dispatch);
    playlistActions.getSimple(dispatch);
  }, []);
  useEffect(() => {
    if (isEditing) {
      const campaignPlaylists = campaign.playlists;
      const campaignPlaylistSorts = campaign.playlistSortsDtos;
      const selectedClientPlaylists = campaignPlaylists.filter(p => p.client).map(p => ({
        value: p.id,
        label: p.name
      }))
      const selectedClientPlaylistSorts = campaignPlaylistSorts ? campaignPlaylistSorts.filter(ps => {
        const playlist = playlists.find(pl => pl.id === ps.playlistId)
        return playlist.client;
      })

          .map(p => ({
            value: playlistSortOptionValue(p),
            label: playlistSortName(playlists, p)
          })) : [];

      const selectedClientPlaylistElements = selectedClientPlaylists.concat(selectedClientPlaylistSorts);
      setSelectedClientPlaylists(selectedClientPlaylistElements);
      const selectedMasterPlaylists = campaignPlaylists.filter(p => !p.client).map(p => ({
        value: p.id,
        label: p.name
      }));
      const selectedMasterPlaylistSorts = campaignPlaylistSorts ? campaignPlaylistSorts.filter
          (ps => {
            const playlist = playlists.find(pl => pl.id === ps.playlistId);
            return !playlist.client;
          }).map(p => ({
            value: playlistSortOptionValue(p),
            label: playlistSortName(playlists, p)
          }))
          :
          [];
      const selectedMasterPlaylistElements = selectedMasterPlaylists.concat(selectedMasterPlaylistSorts);
      setSelectedMasterPlaylists(selectedMasterPlaylistElements);
    }
  }, [playlists]);

  if (playlists && client) {
    clientPlaylists = playlists.filter(p => p.client && p.client.id === client.value);
    masterPlaylists = playlists.filter(p => !p.client);
  }

  const doArchiving = async () => {
    if (archiving) return;
    setArchiving(true);
    setError('');
    setConfirmArchiving(false);

    try {
      await campaignActions.archive(dispatch, campaign.id);
      setArchiving(false);
      alertActions.notificationSuccess(dispatch, "Sample archived", name);
      onArchive();
    } catch (e) {
      setError(e.userMessage);
      setArchiving(false);
    }
  }

  const closeDeleteConfirmationDialog = async () => {
    setConfirmArchiving(false);
  }

  const handleArchive = async () => {
    if (confirmArchiving)
      return;
    setConfirmArchiving(true);
  }

  const submit = async () => {
    if (!client) {
      setError('Please select a client');
      return;
    }
    if (name === "") {
      setError('Please fill out all required fields');
      return;
    }
    setSubmitting(true);
    setError('');

    let selectedPlaylistElements = selectedClientPlaylists.concat(selectedMasterPlaylists).map(p => {
      return {id: p.value}
    });

    let playlists = selectedPlaylistElements.filter(p => ("" + p.id).indexOf("#") === -1);
    let playlistSorts = selectedPlaylistElements.filter(p => ("" + p.id).indexOf("#") !== -1).map(p => {
      let ids = p.id.split("#");
      return {id: parseInt(ids[1])}
    });


    let campaignData = {
      ...(isEditing ? {id: campaign.id} : {}),
      client: {id: client.value},
      name,
      description,
      playlists: playlists,
      playlistSorts: playlistSorts,
      expirationDate: expirationDate,
      ...(imagePicked && {artworkUrl: campaignArtworkUrl})
    };

    try {
      isEditing ? await campaignActions.updateCampaign(dispatch, campaignData, campaign.id) : await campaignActions.createCampaign(dispatch, campaignData);
      setSubmitting(false);
      alertActions.notificationSuccess(dispatch, isEditing ? "Sample updated" : "Sample created", `${name}`);
      onCreate();
    } catch (e) {
      setError(e);
      setSubmitting(false);
    }
  };

  const playlistSelect = (playlists) => {
    let array = [];
    const stringToColour = function (str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = '#';
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    }
    playlists.forEach(playlist => {
      const playlistLabelHtml = playlist.name + (playlist.playlistGroup ? "&nbsp;<font color='" + stringToColour(playlist.playlistGroup) + "'>(" + playlist.playlistGroup + ")</font>" : "");
      array.push({value: playlist.id, label: playlistLabelHtml});
      if (playlist.playlistSorts) {
        playlist.playlistSorts.forEach(sort => {
          const sortLabelHtml = "&nbsp;" + playlistSortName(playlists, sort) + (playlist.playlistGroup ? "&nbsp;<font color='" + stringToColour(playlist.playlistGroup) + "'>(" + playlist.playlistGroup + ")</font>" : "");
          array.push({value: playlist.id + "#" + sort.id, label: sortLabelHtml});
        });
      }
    });

    return array;
  };
  const campaignImageClicked = () => {
    imageUrlInput.current.click();
  }

  const handleImagePickClose = () => {
    setShowPickImageModal(false);
  }

  const handleImagePickComplete = async (imageUrl) => {
    setShowPickImageModal(false);
    setCampaignArtworkUrl(imageUrl);
    setImagePicked(true);
  }

  const onImageFileChanged = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    await campaignService.uploadArtwork(campaign.id, file)
    const imageUrl = URL.createObjectURL(file);
    setCampaignArtworkUrl(imageUrl);
  };

  const imagesUrlsByClient = isEditing && [...new Set(imagesUrls.filter(i => i.clientId === client.value).map(i => i.logoUrl))];
  return (
      <ThemeProvider theme={theme}>
            <CreationForms
                message={`This will ${
                    isEditing ? "edit a" : "create a new"
                } sample, please check the information entered above is correct.`}
                handleBackButton={onBack}
                handleSubmit={submit}
                deleting={archiving}
                onDelete={isEditing ? handleArchive : null}
                submitting={submitting}
                alert={error}
                title={isEditing ? "Edit Sample" : "Create Sample"}
            >
              {(isEditing &&
                  <DetailsContainer style={{marginBottom: 35}}>
                        <div className={"d-flex"}>
                            <div>
                                <Image hue={!campaignArtworkUrl && (hashCode(campaign.name) % 360)} size={180}
                                    onClick={campaignImageClicked}>
                                    {(campaignArtworkUrl) &&
                                        <>
                                            <img alt={"client logo"}
                                                style={{maxWidth: '100%', maxHeight: '100%'}}
                                                src={campaignArtworkUrl} />
                                        </>}
                                  <input type='file' id='file' ref={imageUrlInput}
                                      style={{display: 'none'}}
                                      onChange={onImageFileChanged}
                                      accept="image/png, image/jpeg" />
                                </Image>
                            </div>
                            <div style={{marginLeft: 20}} className={"d-flex flex-column"}>
                                <div style={{color: "white", fontSize: 24}}>Logo</div>
                                <div style={{color: "#808080", marginTop: 10, lineHeight: "1.5em"}}
                                    className={"flex-fill"}>Upload a Logo.
                                    This will be
                                    displayed in the client's mobile application.
                                    <br />
                                    Please ensure logo is exact square, at least 512x512 and in JPG format
                                </div>
                              {imagesUrlsByClient && imagesUrlsByClient.length > 0 &&
                                  <div style={{
                                    color: "#FFB800",
                                    cursor: "pointer"
                                  }} onClick={() => setShowPickImageModal(true)}>Choose
                                        from existing...</div>}
                              <div style={{color: "#FFB800", marginTop: 10, cursor: "pointer"}}
                                  onClick={campaignImageClicked}>Upload from computer...
                                </div>
                            </div>
                        </div>
                    </DetailsContainer>)}
              {/*{(isEditing &&*/}
              {/*    <DetailsContainer>*/}
              {/*    <Image hue={!campaignArtworkUrl && (hashCode(campaign.name) % 360)} size={180}*/}
              {/*        onClick={campaignImageClicked}>*/}
              {/*        {(campaignArtworkUrl) &&*/}
              {/*            <>*/}
              {/*                <img style={{maxWidth: '100%', maxHeight: '100%'}}*/}
              {/*                    src={campaignArtworkUrl} />*/}
              {/*            </>}*/}
              {/*      <input type='file' id='file' ref={imageUrlInput}*/}
              {/*          style={{display: 'none'}}*/}
              {/*          onChange={onImageFileChanged}*/}
              {/*          accept="image/png, image/jpeg" />*/}
              {/*    </Image>*/}
              {/*</DetailsContainer>)}*/}
              <DetailsContainer>
                    <Label>Choose Client</Label>
                    <RSelect
                        options={clients.sort((a, b) => a.name.localeCompare(b.name)).map(client => ({
                          value: client.id,
                          label: client.name
                        }))}
                        name="client-list"
                        onChange={setClient}
                        value={client}
                        isDisabled={isEditing}
                    />
                    <Helper message="Choose one client" />
                </DetailsContainer>
                <DetailsContainer>
                    <Label>Enter Sample Details</Label>
                    <FormContainerWithHelper>
                        <FormLabel>SAMPLE NAME</FormLabel>
                        <Form
                            type="text"
                            placeholder="Sample Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </FormContainerWithHelper>
                    <FormContainerWithHelper>
                        <FormLabel>SAMPLE DESCRIPTION</FormLabel>
                        <Form
                            type="textarea"
                            placeholder="Sample Description"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                    </FormContainerWithHelper>
                    <FormContainerWithHelper>
                        <FormLabel>EXPIRATION DATE</FormLabel>
                        <DatePickerWrapper style={{marginTop: '5px'}}>
                            <DatePicker
                                minDate={moment().toDate()}
                                selected={expirationDate}
                                onChange={value => setExpirationDate(value)}
                                customInput={<DatePickerInput half />}
                            />
                        </DatePickerWrapper>
                    </FormContainerWithHelper>
                    <FormContainerWithHelper>
                        <FormLabel>CLIENT PLAYLISTS</FormLabel>
                        <div style={{width: '100%', marginTop: '5px'}}>
                            <Select
                                options={playlistSelect(clientPlaylists)}
                                formatOptionLabel={function (data) {
                                  return (
                                      <span dangerouslySetInnerHTML={{__html: data.label}} />
                                  );
                                }}
                                value={selectedClientPlaylists}
                                isSearchable={true}
                                name="add-playlists"
                                isMulti
                                onChange={setSelectedClientPlaylists}
                            />

                        </div>
                    </FormContainerWithHelper>
                    <FormContainerWithHelper>
                        <FormLabel>MASTER PLAYLISTS</FormLabel>
                        <div style={{width: '100%', marginTop: '5px'}}>
                            <Select
                                style={{width: 200}}
                                options={playlistSelect(masterPlaylists)}
                                name="add-playlists"
                                value={selectedMasterPlaylists}
                                formatOptionLabel={function (data) {
                                  return (
                                      <span dangerouslySetInnerHTML={{__html: data.label}} />
                                  );
                                }}
                                isSearchable={true}
                                isMulti
                                onChange={setSelectedMasterPlaylists}
                            />
                        </div>
                    </FormContainerWithHelper>
                </DetailsContainer>
              {imagesUrlsByClient && <ImagePickModal imagesUrls={imagesUrlsByClient} show={showPickImageModal}
                  onHide={handleImagePickClose}
                  onImagePicked={handleImagePickComplete} />}
              {confirmArchiving && (
                  <ModalDecisionBuilder
                      title={"Are you sure you want to archive this sample?"}
                      handleClose={closeDeleteConfirmationDialog}
                      handleAccept={doArchiving}
                  />
              )}
            </CreationForms>
        </ThemeProvider>
  );
}

const mapStateToProps = state => ({
  clients: state.client.client,
  campaigns: state.campaign.campaign,
  playlists: state.playlists.lists.list
});

const connectedCreateCampaign = withRouter(connect(mapStateToProps)(CreateCampaign));
export { connectedCreateCampaign as CreateCampaign };
