import {scheduleConstants} from "_constants";

// Selectors
export const isScheduleLoaded = (state, id) => !state.schedule.loading && Object.keys(state.schedule.scheduleDetails).length > 0;
export const getScheduleDetails = (state, id) => state.schedule.scheduleDetails;
export const getPlaylistsInfo = state => state.schedule.info;

// Reducer
const initialState = {
  loading: false,
  schedule: [],
  scheduleDetails: {},
  info: {}
};
export function schedule(state = initialState, action) {
  switch (action.type) {
    case scheduleConstants.PLAYLIST_INFO_SUCCESS:
      return {
        ...state,
        info: action.info
      }

    case scheduleConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case scheduleConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        schedule: action.schedule
      };
    case scheduleConstants.GET_FAILURE:
      return {
        ...state,
        loading: false
      };
      case scheduleConstants.GET_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case scheduleConstants.GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleDetails: action.schedule
      };
    case scheduleConstants.GET_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case scheduleConstants.ADD_ONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case scheduleConstants.ADD_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        createdSchedule: action.schedule
      };
    case scheduleConstants.ADD_ONE_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
