import React from "react";
import styled, { ThemeProvider } from "styled-components";

import { DefaultColumnContainer, DefaultRowContainer } from './Container.style';
import { DefaultLabel } from './Label.style';
import { theme } from "./Theme";
import moment from 'moment';

const Container = styled(DefaultRowContainer)`
    border-radius: 5px;
    border-right: 2px solid ${props => props.color};
    background-color: #454546;
    justify-content: space-between;
    margin-top: 5px;
    padding: 10px;
    cursor: pointer;
`;

const Label = styled(DefaultLabel)`
    color: white;

    &:before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: ${props => props.color};
        margin-right: 10px;
    }
`;

const GrayLabel = styled(DefaultLabel)`
    font-size: 14px;
    color: #a0a0a0;
`;

const WhiteLabel = styled(DefaultLabel)`
    font-size: 14px;
    color: white;
`;

const YellowLabel = styled(DefaultLabel)`
    font-size: 14px;
    color: #f7c137;
`;


const LeftContainer = styled(DefaultColumnContainer)`
    align-items: flex-start;
`;

const RightContainer = styled(DefaultColumnContainer)`
    align-items: flex-end;
`;

export const Overrides = ({override, handleClick, color}) => {
  let dateRange = `${moment(override.start).format('MM/DD')} - ${moment(override.end).subtract(1, 'd').format('MM/DD')}`;
  let time = `${moment(override.start).format('hh:mm A')} - ${moment(override.end).format('hh:mm A')}`;
  let frequency = override.weekly ? 'WEEKLY' : 'DAILY';
  const daysOfWeek = override.daysOfTheWeek;
  const days = daysOfWeek ? daysOfWeek.map(d => moment().day(d).format("ddd")).join(", ") : "Everyday";

  return (
      <ThemeProvider theme={theme}>
      <Container onClick={handleClick} color={color}>
        <LeftContainer>
          <Label color={color}>{override.title}</Label>
          <GrayLabel>{time}</GrayLabel>
        </LeftContainer>
        <RightContainer title={days}>
          <GrayLabel>{dateRange}</GrayLabel>
          {override.weekly ? <YellowLabel>{frequency}</YellowLabel> : <WhiteLabel>{frequency}</WhiteLabel>}
        </RightContainer>
      </Container>
    </ThemeProvider>
  );
};
