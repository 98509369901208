import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { alertActions, scheduleActions } from "_actions";
import {
  ClientDropdownFilter,
  CreateSchedule,
  FilterSection,
  PrimaryButton,
  RowSpaced,
  SearchFilter,
  SectionGutter,
  SectionHeader,
  SectionTitle
} from "_components";
import { SortTable } from '_components/Standard';
import { Matches } from "_helpers";
import { StandardMoment } from "_styles";

class Schedules extends React.Component {
  state = {
    filterText: sessionStorage.getItem('schedule_filter_text') || "",
    showCreateSchedule: false,
    filterClientId: -1
  };

  componentDidMount() {
    const {dispatch} = this.props;

    dispatch(alertActions.clear());
    dispatch(scheduleActions.get());
  }

  handleDeleteSchedule = id => {
    this.props.dispatch(scheduleActions.deleteOne(id));
  };

  handleSearch = filterText => {
    this.setState({filterText: filterText}, () => sessionStorage.setItem('schedule_filter_text', filterText.toLowerCase()));
  };

  createSuccess = () => {
    this.setState({showCreateSchedule: false}, () => {
      this.props.dispatch(scheduleActions.get());
      this.props.history.push(`/schedule/${this.props.createdSchedule.id}`);
    });
  };

  showScheduleDetails = original => {
    this.props.history.push(`/schedule/${original.id}`);
  };

  filterClient = (client) => {
    this.setState({filterClientId: client ? client.id : -1});
  }

  render() {
    const {showCreateSchedule} = this.state;
    const {schedule = [], loading} = this.props;

    let data = schedule.filter(l => this.state.filterClientId === -1 || l.client.id === this.state.filterClientId);
    data = Matches(data, this.state.filterText, ["name"]);
    data.forEach(d => d['dateUpdated'] = d.dateUpdated ? new Date(d.dateUpdated) : new Date(d.dateCreated));
    return (
        <>
                {showCreateSchedule && (
                    <CreateSchedule onBack={() => this.setState({showCreateSchedule: false})}
                        onCreate={this.createSuccess} />
                )}
          <SectionHeader>
                    <RowSpaced>
                        <SectionTitle>Schedules</SectionTitle>
                        <PrimaryButton onClick={() => this.setState({showCreateSchedule: true})}>
                            + Add Schedule
                        </PrimaryButton>
                    </RowSpaced>

                    <FilterSection>
                        <SearchFilter onChange={this.handleSearch} value={this.state.filterText} />
                        <ClientDropdownFilter clientSelect={this.filterClient} />
                    </FilterSection>
                </SectionHeader>
                <SortTable
                    tableName={'schedule'}
                    defaultSortField='client.name'
                    onRowClick={this.showScheduleDetails}
                    loading={loading}
                    rows={data}
                    gutter={SectionGutter}
                    columns={[
                      {percent: 20, title: 'Client', field: 'client.name'},
                      {
                        percent: 30, title: 'Name', field: 'name',
                        rowRenderer: row => (<>
                                <span
                                    style={{color: row.zones.length > 0 ? "#02FF00" : "transparent"}}>●&nbsp;</span> {row.name}</>)
                      },
                      {fixed: 80, title: 'Zones', isNumber: true, field: 'zones.length', dim: true},
                      {fixed: 80, title: 'Playlists', isNumber: true, field: 'playlists.length', dim: true},
                      {fixed: 80, title: 'Dayparts', isNumber: true, field: 'dayParts.length', dim: true},
                      {fixed: 80, title: 'Tracks', isNumber: true, field: 'totalTracks', dim: true},
                      {
                        fixed: 190,
                        title: 'date updated (Local)',
                        isNumber: true,
                        field: "dateUpdated",
                        dim: true,
                        rowRenderer: row => (
                            <StandardMoment>{row.dateUpdated}</StandardMoment>
                        )
                      },
                    ]}
                />
            </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.schedule.loading,
  alert: state.alert,
  schedule: state.schedule.schedule,
  createdSchedule: state.schedule.createdSchedule
});

const connectedSchedule = withRouter(connect(mapStateToProps)(Schedules));
export { connectedSchedule as Schedule };
